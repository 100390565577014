<template lang="html">
  <section class="advanced-search">
    <div class="container">
      <div @click="goToBack()" class="btn-back" title="Volver a la página anterior">
        Volver
      </div>

      <div class="container-search-box">
        <h1 class="title-cat-big">Búsqueda avanzada</h1>
        <Form action="" class="search-form" >
          <div class="column">
            <label for="keyword">Buscar por palabra</label>
            <input name="keyword" class="input-styled keyword" placeholder="Introduce tu palabra" title="Introduce tu palabra"
              v-model="data.keyword" />
          </div>
          <div class="column">
            <label for="typeContent">Tipo de contenido</label>
            <select as="select" name="typeContent" class="input-styled typeContent" placeholder="Tipo de contenido"
              v-model="data.typeContent">
                    <optgroup>

              <option value="entries" title="noticias">Noticias</option>
              <option value="events" title="eventos">Eventos</option>
              <option value="opinions" title="opiniones">Opiniones</option>
              <option value="interviews" title="entrevistas">Entrevistas</option>
              <option value="coverages" title="reportajes">Reportajes</option>
              <option value="files" title="documentos">Documentos</option>
              <option value="medias" title="audiovisuales">Audiovisuales</option>
              </optgroup>
            </select>
          </div>
          <div class="column" v-if="themes">
            <label for="theme">Seleccionar temática</label>
            <select as="select" name="theme" class="input-styled theme" placeholder="Tipo de contenido"
              v-model="data.theme">
                    <optgroup>

              <option :value="null" title="null">{{cleanTextTheme}}</option>
              <template v-for="theme in themes" :key="theme.id">
                <option :value="theme" :title="theme.name">{{ theme.name }} </option>
              </template>
         </optgroup>
            </select>
          </div>
          <div class="column" v-if="zones">
            <label for="location">Seleccionar ubicación</label>
            <select as="select" name="location" class="input-styled location" placeholder="Seleccionar ubicación"
              v-model="data.location">
              <optgroup>
              <option :value="null" title="null">{{cleanTextZone}}</option>
              <template v-for="zone in zones" :key="zone.id">
                <option :value="zone" :title="zone.name">{{ zone.name }} </option>
              </template>
              </optgroup>
            </select>
          </div>
          <div class="column middle-width">
            <label for="dateStart">Fecha desde</label>
            <Datepicker placeholder="Selecciona la fecha" v-model="data.dateStart" name="dateStart" locale="es"
              autoApply :enableTimePicker="false">
            </Datepicker>
          </div>
          <div class="column middle-width">
            <label for="dateEnd">Fecha hasta</label>
            <Datepicker placeholder="Selecciona la fecha" v-model="data.dateEnd" name="dateEnd" locale="es" autoApply
              :enableTimePicker="false">
            </Datepicker>
          </div>
          <div class="row-center w-100">
            <dive class="btn-white" @click="sendFilters">Buscar</dive>
          </div>
        </Form>
      </div>

      <!-- {{results}} -->
      <section class="grid-3">
        <template v-if="results">
          <div class="w-100">
            <p class="title-dest-underline">Resultados de la búsqueda</p>
          </div>
          <template v-for="(result, key) in results" :key="key">
            <newVerticalImg :dataContent="result"></newVerticalImg>
          </template>
        </template>
        <div v-else class="container-noResult">
          <div class="w-100">
            <p class="title-dest-underline">No hay resultados</p>
          </div>
        </div>
      </section>
      <!-- <paginator :pageActual="page" :totalItems="ResultMaxItems" @clicked="changePages"></paginator> -->
      <!-- <gridContentsFooter></gridContentsFooter> -->
    </div>
  </section>
</template>

<script lang="js">
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex';
  import router from '@/router';
  import store from '@/store';
  import Datepicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css';
  import newVerticalImg from "@/components/news/new-featured-vertical-img.vue";
  // import paginator from "@/components/paginator.vue";
  // import gridContentsFooter from "@/components/content-footer-dinamic.vue";
  import {
    Form,
    // Field,
    defineRule,
    configure,
    ErrorMessage
  } from "vee-validate";
  export default {
    name: 'advanced-search',
    props: ['text'],
    components: {
      // Field,
      Form,
      Datepicker,
      newVerticalImg,
      // paginator,
      // gridContentsFooter
    },
    mounted() {

    },
    data() {
      return {
        data: {
          keyword: this.text,
          typeContent: 'entries',
          theme: null,
          location: null,
          dateStart: null,
          dateEnd: null
        }
      }
    },
    methods: {
      goToBack() {
        this.$router.back()
      },
      ...mapActions({
        loadResults: 'form/loadResults'
      }),
      ...mapMutations({
        setPageResults: 'form/setPageResults',

      }),
      changePages(item) {
        this.setPageResults(item)
        this.loadResults({
          page: this.page,
        });
        //utils.LoaderScrollToTop();
      },
      sendFilters() {
        this.loadResults({
          // length: 12,
          page: this.page,
          filtersResults: {
            'textFilter': this.data.keyword,
            'typeContent': this.data.typeContent,
            'theme': this.data.theme,
            'zone': this.data.location,
            'dataStart': this.data.dateStart,
            'dataEnd': this.data.dateEnd
          }
        });
      },

    },
    computed: {
      ...mapGetters({
        themes: 'contents/getThemes',
        zones: 'contents/getZones',
        getPagesResults: 'form/getPagesResults',
        page: 'form/getResultsViewPage',
      }),

      cleanTextTheme() {
        if (this.data.theme != null) {
          return 'Eliminar selección'
        } else {
          return 'Seleccionar temática'
        }
      },
      cleanTextZone() {
        if (this.data.location != null) {
          return 'Eliminar selección'
        } else {
          return 'Seleccionar ubicación'
        }
      },
      results: function () {
        return store.getters['form/getResults'](this.page)
      },
      filtersResultsStore: function () {
        return store.getters['form/getResultsLastFilters']
      },

      ResultMaxItems() {
        return this.getPagesResults();
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/colours.scss";

  .advanced-search {
    h1 {
      text-align: center;
      border-top:0;
    }

    .title-dest-underline {
      width: max-content;
      margin: 0 auto;
      margin-top: 65px;
      text-align: center;
      margin-bottom: 20px;
    }

    .container-noResult {
      margin: 0 auto,
    }
  }

  @media screen and (max-width:845px) {
    .container-search-box {
      max-width: 100%;
    }
  }

  @media screen and (max-width:750px) {
    .column.middle-width {
      width: 100%;
    }

  }
</style>